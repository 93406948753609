import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import theme from '../../../util/theme';

export const WrapLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction: row;
margin-bottom: -5px;
`;

export const InputLabel = styled.div`
font-size: 13px;
font-family: 'Inter', sans-serif;
color:#000;
;
`

export const RequiredLabel = styled.div`
font-size:11px;
margin-top:3px;
margin-left:2px;
color:${theme.colors.primary};
font-weight: bold;
`

export const InputStyled = styled(Field)`
  width: 100%;
  height: 30px;
  background: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: 2px solid ${theme.colors.primary};
  color: #000;
  ::placeholder {
    color: #000;
    font-size:12px;
    font-family: 'Inter', sans-serif;  
  }  
  ::placeholder::after {
    color: #000;
    content: "*";
  }  
  :focus {
    outline: none;
    border-bottom: 2px solid ${theme.colors.third};
  }
  padding:0;
`

export const InputMaskStyled = styled(MaskedInput)`
  width: 100%;
  height: 30px;
  background: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: 2px solid ${theme.colors.primary};
  color: #000;
  ::placeholder {
    color: #000;
    font-size:12px;
    font-family: 'Inter', sans-serif;  
  }  
  ::placeholder::after {
    color: #000;
    content: "*";
  }  
  :focus {
    outline: none;
    border-bottom: 2px solid ${theme.colors.third};
  }
  padding:0;
`
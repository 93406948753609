import styled from 'styled-components'

export const ListView = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    margin: 0;
    padding: 0;
`

import  styled from 'styled-components'
import {Search} from 'react-feather'


export const Wrap = styled.div`
width:100%;
height: auto;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-bottom: 40px;
`

export const Container = styled.div`
width:100%;
height: auto;
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
margin-top: 100px;
@media (max-width: 768px) {  
  margin-top: 40px;
}
`

export const WrapInputSearch = styled.div`
width: 50%;
height: 40px;
display: flex;
border: 1px solid #E5E2E2;
background-color: #F5F5F5;
border-radius: 4px;
flex-direction: row;
@media (max-width: 768px) {    
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`

export const InputSearchIcon = styled(Search)`
height: 100%;
line-height: 40px;
color: #F05874;
margin-left:10px;
@media (max-width: 768px) {
  display :none ;  
}
`

export const InputSearch = styled.input`
width:100%;
padding-left:10px;
padding-right:10px;
border: none;
background: transparent;
outline: none;
font-size: 16px;
font-family: 'Roboto';

`



export const InputButtonDesktop = styled.div`
height: 41px;
line-height: 40px;
cursor: pointer;
padding-left:35px;
padding-right:35px;
background-color: #F05874;
color:#fff;
font-family: 'Roboto';
background-color: #F05874;
border-radius:4px;
text-align: center;
margin-left: 20px;
@media (max-width: 768px) {
  display :none ;
}
`

export const InputButtonMob = styled(Search)`
height: 41px;
line-height: 40px;
cursor: pointer;
padding-left:35px;
padding-right:35px;
background-color: #F05874;
color:#fff;
font-family: 'Roboto';
background-color: #F05874;
border-radius:4px;
text-align: center;
margin-left: 10px;
@media (max-width: 768px) {
  display :block ;
  padding-left:10px;
  padding-right:10px;
}
@media (min-width: 768px) {
  display :none ;
}
`

export const MsgError = styled.div`
width: 63%;
height: auto;
display: flex;
justify-content: start;
color:#F05874;
font-family: 'Inter';
margin-top:5px
`
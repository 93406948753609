import React from 'react'


type Props = {
  sp: string

}
const Divisor: React.FC<Props> = ({ sp }) => (
  <div style={{ width: '100%', height: sp }} />
)

export default Divisor
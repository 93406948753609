import { Backend } from '../../../../../common/infra/backend'
import { HttpConnector } from '../../../../../common/infra/http-connector/http-connector'
import { Order } from '../../applications/domain/order'
import { ResponseCreateOrderRemote } from './create-order-remote.dto'
import { CreateOrderConnectorProtocol } from './create-order-remote.protocol'

export class CreateOrderRemote
    extends HttpConnector
    implements CreateOrderConnectorProtocol
{
    private static ENDPOINT = Backend.makeUrl(`orders`)
    async create(data: Order): Promise<ResponseCreateOrderRemote> {
        try {   
            const url = CreateOrderRemote.ENDPOINT
            const response = await this.post({ url, data })
            return response.status === 200 && response.data
        } catch (error) {
            throw new Error('Error when creating remote order')
        }
    }
}

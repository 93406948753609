import React, { useState } from 'react'
import { AlertContextProtocol } from '../context/alert.context.protocol'
import { AlertContextState } from '../context/alert.context.state'
import { AlertContext } from '../context/alert.context'
import { Alert } from '../../../common/ui'

interface Props {
    children: JSX.Element[] | JSX.Element
}

export const AlertProvider: React.FC<Props> = ({ children }) => {
    const [{ alert }, showAlert] = useState<AlertContextProtocol>(AlertContextState)
    return (
        <AlertContext.Provider value={{ alert, showAlert }}>
            <Alert></Alert>

            {children}
        </AlertContext.Provider>
    )
}

import styled from 'styled-components'
import { Eye as EyeIcon } from 'react-feather'

export const WrapItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #efefef;
    background-color: #fafafa;  
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    :hover {
        background-color: #f3f2f2;
    }
`

export const Description = styled.p`
    width: 100%;
    height: auto;
    text-align: justify;
    color: #000;
    font-family: 'Roboto';
    padding: 0;
    margin: 0;
`

export const WrapInfos = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 15px;
`

export const ViewInfo = styled.div`
    width: auto;
    height: auto;
    color: #dddddd;
    font-family: 'Roboto';
    flex-direction: row;
    line-height: 25px;
`

export const ViewIcon = styled(EyeIcon)`
    color: #dddddd;
    width: 20px;
    margin-left: 5px;
    stroke-width: 2px;
`

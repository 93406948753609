import { SignupRemote } from '../../infra/signup-remote/signup-remote'
import { SignupController } from '../controllers/signup.controller'
import { SignupUserStateProtocol } from '../protocols/signup-user-state.protocol'
import { SignupUserUseCase } from '../usecases/signup-user/signup-user.usecase'

export const signupUserFactory = (state: SignupUserStateProtocol) => {
    const connector = new SignupRemote()
    const usecase = new SignupUserUseCase(connector)
    return new SignupController(usecase).submit(state)
}

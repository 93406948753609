import { useParams } from 'react-router-dom'
import { Container, Header, Layout } from '../../../../common/ui'
import { useEffect } from 'react'
import { Description, DescriptionWrap, LoadingWrap, Title } from './style'
import { ImgLoad } from '../../../../common/ui/buttons/style'

const Solicitation = () => {
    const { id } = useParams()

    useEffect(() => {
        console.log(id)
    }, [id])

    return (
        <Layout>
            <Container>
                <Header />
                <Title>Nike</Title>
                <DescriptionWrap>
                    <Description>
                        Espere só um pouquinho, estamos aguardando a resposta
                        dos nossos parceiros, que estarão prontos para fornecer
                        exatamente o que você precisa.
                        <br />
                        <br />
                        Em breve, aparecerá a lista de empresas que têm o que
                        você precisa.
                    </Description>
                </DescriptionWrap>
                <LoadingWrap>
                    <ImgLoad
                        style={{ width: '80px' }}
                        src="/imgs/load-dots.svg"
                    />
                </LoadingWrap>
            </Container>
        </Layout>
    )
}

export default Solicitation

import styled from 'styled-components'
import { MAX_MEDIA_MOBILE } from '../../../util/theme'

interface Props {
    selected?: boolean
}

export const WrapMenu = styled.div`
    width: 190px;
    height: 100vh;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: fixed;
    left:0;
    @media (max-width: ${MAX_MEDIA_MOBILE}) {
        display: none;
    }
`
export const Logo = styled.img`
    width: 100%;
    margin-bottom: 50px;
    @media (max-width: 800px) {
       
    }
`

export const Link = styled.a<Props>`
    width: 100%;
    height: auto;    
    margin-bottom: 20px;
    color: ${p => (p.selected ? '#F05874' : '#000')};
    font-family: 'Inter';
    font-size: 14px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    line-height: 25px;
    :hover {
        color: #f05874;
    }
`

export const Icon = styled.div`
    margin-right: 10px;
`

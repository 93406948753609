import { useEffect } from "react"
import { useAlertShow } from "../../../providers/alert/context/alert.context.dispatcher"
import { AlertBox, TextMessage } from './style'
import { AlertCircle, Check } from 'react-feather'
const Alert = () => {
  const { alert, showAlert } = useAlertShow()

  useEffect(() => {
    alert.message !== '' &&
      alert.status !== 'false' &&
      showAlert && setTimeout(
        () => showAlert({ alert: { message: '', status: 'false' }, showAlert }),
        3000,
      )
  }, [alert, showAlert])

  return (
    <>
      <AlertBox show={alert.status !== 'false'}>
        {alert.status === 'error' && <AlertCircle color="white" />}
        {alert.status === 'success' && <Check color="white" />}
        <TextMessage>{alert.message}</TextMessage>
      </AlertBox>
    </>
  )
}

export default Alert
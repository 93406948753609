import { Link, LinkStyle } from './style'
import { linksSiteMap } from '../../../../links/site'

export const Links = () => {
  const links = (l: any, i: any) => <Link key={i} href={l.href}>{l.name}</Link>

  return (
    <LinkStyle>
      {linksSiteMap.map(links)}
    </LinkStyle>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
height:100vh;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Inter', sans-serif;
font-weight: bold;
`
import { Sectors } from '../../../../../../common/sectors'
import { SetFieldValue } from '../../../../../../domain/types/set-value-fields/set-value-field.type'

export const select = {
    set: (e: React.ChangeEvent<HTMLSelectElement>, setField: SetFieldValue) => {
        const sectorId = e.target.value
        const sectorName = Sectors.find(s => s.code === sectorId)?.name
        setField('sectorId', sectorId)
        setField('sector', sectorName)
    },

    options: Sectors.map(sector => (
        <option key={sector.code} value={sector.code}>
            {sector.name}
        </option>
    )),
}

import { useState } from 'react'
import { WrapMenuMob, MenuButton, MenuContainer, CloseMenuButton, ContainerCloseMenu } from './style'
import { Links } from './links'

export const MenuMob = () => {
  const [menuEnabled, setShow] = useState<boolean>(false)
  const showMenuAction = () => setShow(true)
  const closeMenuAction = () => setShow(false)

  return (
    <>
      {
        menuEnabled && (
          <MenuContainer>
            <ContainerCloseMenu>
              <CloseMenuButton size={35} onClick={closeMenuAction} />
            </ContainerCloseMenu>
            <Links />
          </MenuContainer>
        )
      }
      <WrapMenuMob>
        <MenuButton onClick={showMenuAction} />
      </WrapMenuMob>
    </>
  )
}
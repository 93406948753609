import { Link, Icon } from './style'
import { Home as HomeIcon, BarChart2, FileText } from 'react-feather'

export const SideMenuLinks = () => {
    return (
        <>
            <Link href="/dash" selected>
                <Icon>
                    <HomeIcon />
                </Icon>
                HOME
            </Link>

            <Link href="/dash">
                <Icon>
                    <BarChart2 />
                </Icon>
                TRENDING
            </Link>

            <Link href="/dash">
                <Icon>
                    <FileText />
                </Icon>
                MEUS DADOS
            </Link>
        </>
    )
}

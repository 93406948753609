import { SigninRemote } from "../../infra/signin-remote/signin-remote"
import { SigninController } from "../controllers/signin.controller"
import { SigninStateProtocol } from "../protocols/signin-user-state.protocol"
import { SigninUseCase } from "../usecases/signin.usecase"

export const signinUserFactory = (state: SigninStateProtocol) => {
  const connector = new SigninRemote()
  const usecase = new SigninUseCase(connector)
  return new SigninController(usecase).submit(state)
}

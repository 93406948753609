import {
    WrapTopBanner,
    WrapTitle,
    Title,
    SubTitle,
    BoldSubTitle,
    WrapTopImage,
    TopImg,
} from './style'

export const TopBanner = () => {
    return (
        <WrapTopBanner>
            <WrapTitle>
                <Title>
                    Encontre o que precisa de
                    <br />
                    forma rápida e simples
                </Title>

                <SubTitle>
                    Conte-nos o que precisa, e iremos verificar se aqui{' '}
                    <BoldSubTitle>tem.</BoldSubTitle>
                </SubTitle>
            </WrapTitle>

            <WrapTopImage>
                <TopImg src="/imgs/top.svg" />
            </WrapTopImage>
        </WrapTopBanner>
    )
}

import { AlertColor } from '../../../../../providers/alert/context/alert.context.protocol'

export class SignupAlertsMessages {
    private static alert = (message: string, status: AlertColor) => ({
        alert: {
            message,
            status,
        },
    })

    static failed = SignupAlertsMessages.alert(
        'Error ao cadastrar o usuário',
        'error'
    )

    static success = SignupAlertsMessages.alert(
        'Parabéns, agora você faz parte do aítem',
        'success'
    )
}

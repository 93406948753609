import axios from "axios";
import { HttpConnectorResponse, HttpPostParam } from "./http-connector.interface";

export class HttpConnector{
  async post(param: HttpPostParam):Promise<HttpConnectorResponse>{
    const {url,data} = param
    const response =  await axios.post(url, data)
    return {
      data: response.data,
      status: response.status
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    colors: {
        background: '#F5F5F5',
        primary: '#F05874',
        second: '#fff',
        third: '#000',
        error: '#ff5252',
        border: '#D7D3D3',
    },
}


export const MAX_MEDIA_MOBILE = "820px"
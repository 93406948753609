import { ErrorMessage } from 'formik'
import React from 'react'
import { Error } from './style'
interface Props {
    name: string
}

export const ErrorInput: React.FC<Props> = ({ name }) => (
    <Error>
        <ErrorMessage name={name} />
    </Error>
)


import { useState } from 'react'
import { WindowTitle } from '../../../../common/ui'
import { User } from '../../../../domain/entity/user'
import { SignupForm } from './form'
import { SignupFormState } from '../state/signup-form.state'
import { Container, Logo, TitleForm, WrapForm, WrapLogo } from './style'
import { useAlertShow } from '../../../../providers/alert/context/alert.context.dispatcher'
import { signupUserFactory } from '../application/factories/signup.factory'

const Signup = () => {
    const [dataForm] = useState(SignupFormState)
    const [load, setLoad] = useState<boolean>(false)
    const { showAlert } = useAlertShow()

    const submit = async (data: User) =>
        signupUserFactory({ data, setLoad, showAlert })

    return (
        <Container>
            <WindowTitle title="Cadastro" />
            <WrapLogo>
                <Logo src="./imgs/logo.png" alt="Logotipo da Aítem" />
            </WrapLogo>
            <WrapForm>
                <TitleForm>CADASTRE SEU NEGÓCIO</TitleForm>
                <SignupForm
                    dataForm={dataForm}
                    handleSubmit={submit}
                    load={load}
                />
            </WrapForm>
        </Container>
    )
}

export default Signup

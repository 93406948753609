import styled from 'styled-components'
import theme from '../../../../util/theme'

export const WrapMenuDesktop = styled.div`
    margin-top: 5px;
    @media (max-width: 768px) {
        display: none;
    }
`

export const Link = styled.a<{ $borded?: boolean }>`
    width: 100%;
    height: auto;
    text-align: center;
    font-family: 'Roboto';
    font-weight: bold;
    text-decoration: none;
    color: ${theme.colors.third};
    margin-left: 20px;
    border-radius: 5px;
    border: ${props => props.$borded && `2px solid ${theme.colors.third}`};
    padding: 10px;
`

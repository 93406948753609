import { Container, Header, Layout } from '../../../common/ui'
import { AboutWrap, TextAbout, AboutTitle } from './style'
import { TopBanner } from '../home/view/top-banner'

const About = () => {
    return (
        <Layout>
            <Container>
                <Header />
                <TopBanner />
                <AboutWrap>
                    <AboutTitle>Como funciona?</AboutTitle>
                    <TextAbout>
                        Bem-vindo ao AíTem, a plataforma que simplifica sua
                        busca por produtos e serviços de forma rápida, eficaz e
                        minimalista!
                        <br />
                        <br />
                        Em um mundo onde cada segundo conta, estamos aqui para
                        tornar sua procura fácil e sem complicações. Imagine
                        querer algo específico e não ter que perder tempo
                        navegando por diversas lojas online, fazendo ligações,
                        esperando respostas ou gastando energia ou combustível
                        indo de loja em loja só para ver se lá tem o que você
                        quer.
                        <br />
                        <br />
                        Estamos aqui para simplificar sua vida, conectando você
                        diretamente ao que procura, quando precisa. Encontre o
                        que deseja de maneira rápida, simples e específica.
                        <br />
                        <br />
                        Funcionamos da seguinte forma: basta digitar o produto
                        ou serviço desejado na página inicial, e iremos
                        verificar se nossos parceiros tem o que você procura.
                    </TextAbout>
                </AboutWrap>
            </Container>
        </Layout>
    )
}

export default About

import React, { Children } from 'react'
import {
    Background,
    ContainerModal,
    WrapCloseButton,
    CloseButton,
    BodyModel,
} from './style'

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    children: any
}

const Modal: React.FC<Props> = ({
    setShowModal: setShowModalCategory,
    children,
}) => {
    return (
        <Background>
            <ContainerModal>
                <WrapCloseButton>
                    <CloseButton onClick={() => setShowModalCategory(false)} />
                </WrapCloseButton>
                <BodyModel>{Children.map(children, child => child)}</BodyModel>
            </ContainerModal>
        </Background>
    )
}

export default Modal

import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    ButtonStyled,
    IconButtonInsert,
    TextButtonInsert,
    WrapButtonInsert,
    ImgLoad,
} from './style'

type Props = {
    label?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
    type?: 'button' | 'submit' | 'reset' | undefined
    disabled?: boolean
    load?: boolean
}

const Common: React.FC<Props> = ({ label, onClick, type, disabled, load }) => (
    <ButtonStyled
        onClick={onClick}
        data-testid={label}
        type={!type ? 'submit' : type}
        disabled={disabled || load}
    >
        {load ? <ImgLoad src="/imgs/load.svg" alt="loading button" /> : label}
    </ButtonStyled>
)

type PropsButtonInser = {
    page: string
}
const ButtonInsert: React.FC<PropsButtonInser> = ({ page }) => {
    const navigator = useNavigate()
    return (
        <WrapButtonInsert
            onClick={() => navigator(page)}
            data-testid="btn-insert"
        >
            <IconButtonInsert />
            <TextButtonInsert>Novo</TextButtonInsert>
        </WrapButtonInsert>
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Common,
    ButtonInsert,
}

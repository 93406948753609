import { MenuDesktop } from './menu-desktop'
import { MenuMob } from './menu-mob'

const Menu = () => {
  return (
    <>
      <MenuDesktop />
      <MenuMob />
    </>
  )
}

export default Menu
import * as Ably from 'ably'

type RealTimeNotificationProps = {
    channel: string
}

export class RealTimeNotification {
    private client: Ably.Realtime
    private channel: Ably.RealtimeChannel

    constructor({ channel }: RealTimeNotificationProps) {
        this.client = new Ably.Realtime({
            key: '2Cn52A.s1FAMA:Yu2R-3QPklNffpnhTcZR9adnlO84cC9RKMZUAReyM74',
        })
        this.client.connect()
        this.channel = this.client.channels.get(channel)
    }

    subscribe(
        event: string,
        listener?: Ably.messageCallback<Ably.InboundMessage> | undefined
    ) {
        this.channel.subscribe(event, listener)
        return this
    }

    unsubscribe(
        event: string,
        listener?: Ably.messageCallback<Ably.InboundMessage>
    ) {
        this.channel.unsubscribe(
            event,
            listener
                ? listener
                : _ => {
                      console.log(
                          `unsubscribe event: ${event} and channel:${this.channel}`
                      )
                  }
        )
    }
}

import { Container, Layout } from '../../../common/ui'
import { WarpIntron, Logo } from './style'

const Intro = () => {
    return (
        <Layout>
            <Container>
                <WarpIntron>
                    <Logo src="/imgs/logo.png" />
                </WarpIntron>
            </Container>
        </Layout>
    )
}

export default Intro

import { Children } from 'react'
import { ContainerStyle, Wrap } from './style'

interface Props {
  children: any
}

const Container: React.FC<Props> = ({ children }) => {
  return (
    <Wrap>
      <ContainerStyle>
        {Children.map(children, child => child)}
      </ContainerStyle>
    </Wrap>
  )
}

export default Container
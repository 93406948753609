import styled from 'styled-components'

export const WrapTopBanner = styled.div`
width:100%;
height: auto;
display: flex;
flex-direction: row;
margin-top: 120px;
@media (max-width: 768px) {
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  margin: 0;
  padding:0;  
  margin-top: 20px;
}
`

export const WrapTitle = styled.div`
width: 50%;
height: auto;
display: flex;
flex-direction: column;
margin-top: 10%;
@media (max-width: 768px) {
  width: 100%;      
  height: auto;
  margin: 0;
}
`

export const Title = styled.div`
font-family: 'Roboto';
font-weight: bold;
font-size: 40px;
@media (max-width: 768px) {
  width: 100%;    
  height: auto;
  margin:20px;
  font-size: 50px;
}
`

export const SubTitle = styled.div`
font-family: 'Open Sans';
font-size: 18px;
margin-top:20px;
text-align: left;
@media (max-width: 768px) {
  width: 100%;    
  height: auto;
  margin: 0;
  font-size: 15px;
  text-align: center;
}
`

export const BoldSubTitle = styled.b`
font-family: 'Open Sans';
font-weight: 700;
`

export const WrapTopImage = styled.div`
width: 50%;
height: auto;
display: flex;
justify-content: end;
@media (max-width: 768px) {  
  width: 100%;
  margin-top :80px;
  justify-content: center;
  align-items: center;
}
`

export  const TopImg = styled.img`
@media (max-width: 768px) {
  width: 80%;
}
`
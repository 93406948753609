import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Form = styled.div`
    width: 100%;
    height: auto;
    margin: 20px;
    @media (min-width: 768px) {
        width: 300px;
    }
`


export const Logo = styled.img`
    width: 100%;
    margin-bottom: 40px;
`

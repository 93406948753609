import Menu from '../menu-site'
import { Top, Logo } from './style'

const Header = () => {
    return (
        <Top>
            <a href="/">
                <Logo src="/imgs/logo.png" alt="Logotipo da Aítem" />
            </a>
            <Menu />
        </Top>
    )
}

export default Header

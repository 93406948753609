import { User } from '../../../../../../domain/entity/user'
import { SignupRemoteProtocol } from '../../../infra/signup-remote/signup-remoto.protocol'

export class SignupUserUseCase {
    constructor(private readonly connector: SignupRemoteProtocol) {}

    async run(user: User): Promise<boolean> {
        return this.connector.create(user)
    }
}

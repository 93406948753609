import styled from 'styled-components'
import { X } from 'react-feather'

export const Background = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`

export const WrapCloseButton = styled.div`
    display: flex;
    justify-content: end;
`

export const CloseButton = styled(X)`
    color: #000;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 10px; ;
`

export const ContainerModal = styled.div`
    width: 480px;
    height: auto;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`

export const BodyModel = styled.div`
    height: auto;
    padding: 30px;
`

export const TitleWhats = styled.div`
    width: 100%;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 12px;
`

export const TitleModel = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-bottom: 80px;
`

export const MsgError = styled.div`
    width: 63%;
    height: auto;
    display: flex;
    justify-content: start;
    color: #f05874;
    font-family: 'Inter';
    margin-top: 5px;
    font-size: 12px;
`

import styled from 'styled-components'

export const AboutWrap = styled.div`
width: 100%;
height:auto;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

`;

export const AboutTitle = styled.div`
width:100%;
height: auto;
margin-top: 80px;
margin-bottom: 80px;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Roboto';
font-weight: bold;
font-size: 30px;
`

export const TextAbout = styled.div`
width: 80%;
height: auto;
text-align: justify;
font-family: 'Inter';
font-size: 18px;
margin-bottom: 80px;
`
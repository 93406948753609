import styled from 'styled-components'
import { MAX_MEDIA_MOBILE } from '../../../util/theme'
import { User as UserFeatherIcon } from 'react-feather'

interface Props {
    top?: string
}

export const Wrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const Header = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    position: fixed;
    justify-content: center;
    @media (max-width: ${MAX_MEDIA_MOBILE}) {
        padding: 0;
    }
`

export const Title = styled.div`
    font-family: 'Inter';
    font-weight: bold;
    font-size: 20px;
`

export const Container = styled.div<Props>`
    width: 60%;
    height: auto;
    margin-left: 120px;
    margin-top: ${p => p.top};
    @media (max-width: ${MAX_MEDIA_MOBILE}) {
        margin-left: 0px;
        width: 100%;
        padding: 15px;
    }
`

export const BoxRight = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 25px;
    padding-right: 30px;
    @media (max-width: ${MAX_MEDIA_MOBILE}) {
        display: none;
    }
`

export const UserIcon = styled(UserFeatherIcon)`
    width: 18px;
    margin-right: 5px;
`

export const WrapUser = styled.div`
    width: 100%;
    font-family: 'Inter';
    font-size: 16px;
    display: flex;
    flex-direction: row;
    line-height: 25px;
`

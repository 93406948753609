import { Buttons, Modal } from '../../../../common/ui'
import { OrderModalContainer, DescriptionModal } from './style'

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    description: string
    submitOrder?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const OrderModal: React.FC<Props> = ({
    setShowModal,
    description,
    submitOrder,
}: Props) => {
    return (
        <Modal setShowModal={setShowModal}>
            <OrderModalContainer>
                <DescriptionModal>{description}</DescriptionModal>
                <Buttons.Common
                    label="Aqui tem"
                    load={false}
                    onClick={submitOrder}
                />
            </OrderModalContainer>
        </Modal>
    )
}

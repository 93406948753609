import { Helmet } from "react-helmet"
interface Props {
  color?: string
}

const Background: React.FC<Props> = ({ color }) => {
  return (
    <Helmet>
      <style>{`body { background-color: ${color ?? '#F5F5F5'}; }`}</style>
    </Helmet>
  )
}

export default Background
import { FilePlus } from 'react-feather'
import styled from 'styled-components'
import theme from '../../../util/theme'


interface Props {
  disabled?: boolean
}

export const ButtonStyled = styled.button<Props>`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Inter', sans-serif;  
  text-align: center;
  color: ${theme.colors.second};
  background: ${theme.colors.primary};
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
  border-radius: 4px;
  border: 0;

  cursor: pointer;
`

export const WrapButtonInsert = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const IconButtonInsert = styled(FilePlus)`
  color: ${theme.colors.third};
  margin-right: 5px;
  margin-top: -5px;
`

export const TextButtonInsert = styled.div`
  color: ${theme.colors.third};
  font-family: 'Inter', sans-serif;  
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

export const ImgLoad = styled.img`
width:20px;
`
import styled from 'styled-components'

export const Title = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: bold;
    margin-top: 120px;
`
export const DescriptionWrap = styled.div`
    width: 100%;
    height: auto;
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Description = styled.div`
    width: 800px;
    text-align: justify;
    font-size: 18px;
    font-family: 'Open Sans';
`

export const LoadingWrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    
`

import styled from "styled-components";
import {Form} from 'formik'

export const FormStyled =  styled(Form)`

@media(min-width: 400px) {
    width:40%;
    height:100%;    
}
@media(max-width: 400px) {    
    width:90%;
}
height:auto
`
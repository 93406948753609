import styled  from 'styled-components'

export const OrderModalContainer = styled.div`
width:100%;
height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`

export const DescriptionModal = styled.div`
width:100%;
height: auto;
color:#000;
font-family: 'Roboto';
margin-bottom: 15px;
`
import { Field } from 'formik'
import React, { useState } from 'react'
import { ErrorInput } from '../input-error'
import { InputMaskEnum } from './enum/InputMaskEnum'
import { maskInput } from './input-mask-regex'
import { InputLabel, InputMaskStyled, RequiredLabel, WrapLabel } from './style'
type Props = {
    mask: InputMaskEnum
    label?: string
    name: string
    onChange?: () => void
    type?: string
    required?: boolean
}
const InputMask: React.FC<Props> = ({ mask, label, name, onChange, type, required }) => {
    const [expandLabel, setExpandLabel] = useState<boolean>(false)

    return (
        <>
            {expandLabel && (
                <WrapLabel>
                    <InputLabel>{label}</InputLabel>
                    <RequiredLabel>*</RequiredLabel>
                </WrapLabel>
            )}

            <Field
                render={({ field }: any) => (
                    <InputMaskStyled
                        {...field}
                        mask={maskInput(mask)}
                        onFocus={() => setExpandLabel(true)}
                        onBlur={() => setExpandLabel(false)}
                        placeholder={!expandLabel ? label : ''}
                        data-testid={name}
                        name={name}
                        {...(onChange && { onChange })}
                        style={{ marginBottom: '20px' }}
                        type={type ? type : 'text'}
                        required={required}
                        value={field[name]}
                    />
                )}
            />
            <ErrorInput name={name} />
        </>
    )
}

export default InputMask

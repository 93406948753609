import styled from 'styled-components'
import theme from '../../../../util/theme'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background-color: ${theme.colors.background};
    display: flex;
    justify-content: space-between;
`

export const WrapLogo = styled.div`
    width: 30%;
    height: 100%;
    background-color: ${theme.colors.second};
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        display: none;
    }
`

export const Logo = styled.img`
    width: 220px;
`

export const WrapForm = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    margin-left: 400px;
    @media (max-width: 600px) {
        margin-left: 0;
    }
`

export const TitleForm = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 20px;
`

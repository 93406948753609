export const linksSiteMap = [
    {
        name: 'Home',
        href: '/',
    },
    {
        name: 'Como funciona',
        href: '/como-funciona',
    },
    {
        name: 'Cadastrar meu negócio',
        href: '/cadastro',
    },
]

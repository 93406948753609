import { User } from '../../../../domain/entity/user'

export const SignupFormState: User = {
    name: '',
    businessName: '',
    description: '',
    sectorId:'',
    sector: '',
    cpfOrCnpj: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    city: '',
    cep: '',
    uf: '',
    address: '',
    socialNetwork: '',
}

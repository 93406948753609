import { Backend } from '../../../../../common/infra/backend'
import { HttpConnector } from '../../../../../common/infra/http-connector/http-connector'
import { Credentials } from '../../application/domain/credentials'
import { SigninRemoteProtocol } from './signin-remote.protocol'
import { SigninRemoteResponseDTO } from './signin-remote.dto'

export class SigninRemote extends HttpConnector implements SigninRemoteProtocol {
    private static ENDPOINT = Backend.makeUrl(`signin`)

    async signin(
        data: Credentials
    ): Promise<SigninRemoteResponseDTO | undefined> {
        try {
            const url = SigninRemote.ENDPOINT            
            const response = await this.post({ url, data})            
            return response.status === 200 && response.data
        } catch (error) {
            return undefined
        }
    }
}

import { CreateOrdrAlertsMessages } from '../alerts-messages/create-order-alerts.messages'
import { CreateOrderStateProtocol } from '../protocols/create-order-state.protocol'
import { CreateOrderUseCase } from '../usecases/create-order/create-order.usecase'

export class CreateOrderController {
    constructor(private readonly createOrderUseCase: CreateOrderUseCase) {}

    async submit(state: CreateOrderStateProtocol) {
        state.setLoadModal(true)
        try {
            const idOrder = await this.createOrderUseCase.run(state.order)
            window.location.href = `/solicitacao/${idOrder}`
        } catch (error) {
            state.showAlert && state.showAlert(CreateOrdrAlertsMessages.failed)
            console.log(error)
        } finally {
            state.setLoadModal(false)
        }
    }
}

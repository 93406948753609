import { SideMenuLinks } from './side-menu-links'
import { WrapMenu, Logo } from './style'

const SideMenu = () => {
    return (
        <WrapMenu>
            <a href="/">
                <Logo src="/imgs/logo.png" alt="Logotipo da Aítem" />
            </a>

            <SideMenuLinks />
        </WrapMenu>
    )
}

export default SideMenu

import styled from 'styled-components'

export const Top = styled.div`
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d7d3d3;
    display: flex;
    justify-content: space-between;
`
export const Logo = styled.img`
    width: 90px;
    @media (max-width: 800px) {
        margin-left: 10px;
    }
`

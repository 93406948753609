import { useState } from 'react'
import { WrapItem, Description, WrapInfos, ViewInfo, ViewIcon } from './style'
import { OrderModal } from '../order-modal'

interface Props {
    description: string
    views: string
    onClick: (data: any) => void
}

const Item: React.FC<Props> = ({ description, views, onClick }: Props) => {
    const [showModalOrder, setShowModalOrder] = useState<boolean>(false)

    const submit = (data: any) => {
        setShowModalOrder(false)
        onClick('teste')
    }

    return (
        <>
            {showModalOrder && (
                <OrderModal
                    submitOrder={submit}
                    setShowModal={setShowModalOrder}
                    description={description}
                />
            )}

            <WrapItem onClick={() => setShowModalOrder(true)}>
                <Description>{description}</Description>
                <WrapInfos>
                    <ViewInfo>{views}</ViewInfo>
                    <ViewIcon />
                </WrapInfos>
            </WrapItem>
        </>
    )
}

export default Item

import styled from 'styled-components'
import theme from '../../../../../util/theme'

export const LinkStyle = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Link = styled.a`
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 30px;
    color: ${theme.colors.primary};
    font-family: 'Roboto';
    font-weight: bold;
    text-decoration: none;
`

import styled from 'styled-components'

interface Props {
    show: boolean
}

export const AlertBox = styled.div<Props>`
    width: 100%;
    background-color: black;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    z-index: 999;
    padding-left: 12%;
    display: ${p => (p.show ? 'flex' : 'none')};
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
`

export const TextMessage = styled.div`
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    margin-top: -2px;
    margin-left: 10px;
`

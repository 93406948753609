import * as yup from 'yup'
import { error } from '../../../../../../common/messages/error/error-validator'

const errorField = (field: string) => error.requiredField(field)

export const signupFormValidator = yup.object().shape({
    name: yup.string().required(errorField('nome')),
    businessName: yup.string().required(errorField('nome do seu negócio')),
    description: yup.string().required(errorField('descrição')),
    sector: yup.string().required(errorField('setor')),
    cpfOrCnpj: yup.string().required(errorField('CPF/CNPJ')),
    email: yup
        .string()
        .required(errorField('email'))
        .email('Preencha com um email válido'),
    password: yup.string().required(errorField('senha')),
    passwordConfirm: yup.string().required(errorField('confirmação de senha')),
    phone: yup.string().required(errorField('telefone/celular')),
    city: yup.string().required(errorField('cidade')),
    cep: yup.string().required(errorField('cep')),
    uf: yup.string().required(errorField('UF')),
    address: yup.string().required(errorField('endereço')),
    socialNetwork: yup.string().required(errorField('link da rede social')),
})

import React, { useState } from 'react'

import { ErrorInput } from '../input-error'
import { InputLabel, InputStyled, RequiredLabel, WrapLabel } from './style'
interface Props {
    label?: string
    name: string
    onChange?: () => void
    type?: string
    required?: boolean
}

const Input: React.FC<Props> = ({ label, name, onChange, type, required }) => {
    const [expandLabel, setExpandLabel] = useState<boolean>(false)

    return (
        <>
            {expandLabel && (
                <WrapLabel>
                    <InputLabel>{label}</InputLabel>
                    <RequiredLabel>*</RequiredLabel>
                </WrapLabel>
            )}

            <InputStyled
                onFocus={() => setExpandLabel(true)}
                onBlur={() => setExpandLabel(false)}
                placeholder={!expandLabel ? label : ''}
                data-testid={name}
                name={name}
                {...(onChange && { onChange })}
                style={{ marginBottom: '20px' }}
                type={type ? type : 'text'}
                required={required}
            />
            <ErrorInput name={name} />
        </>
    )
}

export default Input

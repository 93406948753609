import { SignupAlertsMessages } from '../alerts-messasges/signup-alerts-messages'
import { SignupUserStateProtocol } from '../protocols/signup-user-state.protocol'
import { SignupUserUseCase } from '../usecases/signup-user/signup-user.usecase'

export class SignupController {
    constructor(private readonly signupUserUseCase: SignupUserUseCase) {}

    async submit(state: SignupUserStateProtocol) {
        state.setLoad(true)
        const response = await this.signupUserUseCase.run(state.data)
        state.setLoad(false)
        if (!response) {
            if (state.showAlert) state.showAlert(SignupAlertsMessages.failed)
            return false
        }

        if (state.showAlert) state.showAlert(SignupAlertsMessages.success)
        return true
    }
}

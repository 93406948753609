import { WrapMenuDesktop, Link } from './style'
import { linksSiteMap } from '../../../links/site'

export const MenuDesktop = () => {

  const links = linksSiteMap.map((l, i) => {
    const lastLink = i === linksSiteMap.length - 1
    const diffHome = l.name !== 'Home'
    return diffHome && <Link key={i} $borded={lastLink} href={l.href}>{l.name}</Link>
  })

  return (
    <WrapMenuDesktop>
      {links}
    </WrapMenuDesktop>
  )
}

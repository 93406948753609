import { Formik } from 'formik'
import React from 'react'
import { Buttons, Divisor, Input, Select } from '../../../../../common/ui'
import InputMask from '../../../../../common/ui/input-mask'
import { InputMaskEnum } from '../../../../../common/ui/input-mask/enum/InputMaskEnum'

import { User } from '../../../../../domain/entity/user'
import { select } from './select/select-sector'
import { FormStyled } from './style'
import { signupFormValidator as validator } from './validator'

type Props = {
    dataForm: User
    handleSubmit: any
    load: boolean
}

export const SignupForm: React.FC<Props> = ({
    dataForm,
    handleSubmit,
    load,
}) => (
    <Formik
        enableReinitialize
        initialValues={dataForm}
        validationSchema={validator}
        onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            resetForm()
        }}
    >
        {({ setFieldValue }) => (
            <FormStyled style={{ marginTop: '50px' }}>
                <Input label="Nome" name="name" />
                <Input label="Nome do seu negócio" name="businessName" />
                <Input label="Descreva o seu negócio" name="description" />
                <Select
                    label="Setor"
                    name="sector"
                    change={e => select.set(e, setFieldValue)}
                    showErrorMsg
                >
                    <option>Selecione</option>
                    {select.options}
                </Select>
                <Input label="CPF/ CNPJ" name="cpfOrCnpj" />
                <Input label="Email" name="email" type="email" />
                <Input label="Senha" name="password" type="password" />
                <Input
                    label="Confirmar senha"
                    name="passwordConfirm"
                    type="password"
                />
                <InputMask
                    mask={InputMaskEnum.PHONE}
                    label="Whatsapp"
                    name="phone"
                    type="phone"
                />
                <Input label="Cidade" name="city" />
                <Input label="CEP" name="cep" />
                <Input label="UF" name="uf" />
                <Input label="Endereço" name="address" />
                <Input
                    label="Link da sua rede social (Facebook, instagram...)"
                    name="socialNetwork"
                />
                <Buttons.Common label="Cadastrar" load={load} />
                <Divisor sp="20px" />
            </FormStyled>
        )}
    </Formik>
)

import styled from 'styled-components'
import theme from '../../../util/theme'

export const Error = styled.div`
  width: 100%;
  height: auto;
  margin-top: -20px;
  color: ${theme.colors.error};
  font-family: 'Roboto';
  font-size: 12px;
  padding-top: 10px;
  margin-bottom: 20px;
  opacity: 0.7;
`
import { useState } from 'react'
import { Buttons, Divisor, Layout } from '../../../../common/ui'
import InputNative from '../../../../common/ui/input-native'
import { Container, Form, Logo } from './style'
import { signinUserFactory } from '../application/factories/signin.factory'
import { useAlertShow } from '../../../../providers/alert/context/alert.context.dispatcher'

const Signin = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [load, setLoad] = useState<boolean>(false)
    const { showAlert } = useAlertShow()

    const submit = async () => {
        const data = { email, password }
        signinUserFactory({ data, setLoad, showAlert })
        setEmail('')
        setPassword('')
    }

    return (
        <Layout>
            <Container>
                <Form>
                    <Logo src="./imgs/logo.png" alt="logotipo da aítem" />
                    <InputNative
                        name="email"
                        type="email"
                        label="Email"
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value)
                        }}
                    />
                    <Divisor sp="20px" />
                    <InputNative
                        name="password"
                        type="password"
                        label="Senha"
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Divisor sp="40px" />
                    <Buttons.Common
                        label="Login"
                        load={load}
                        onClick={submit}
                    />
                </Form>
            </Container>
        </Layout>
    )
}

export default Signin

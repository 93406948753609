import { SigninRemoteProtocol } from "../../infra/signin-remote/signin-remote.protocol";
import { Credentials } from "../domain/credentials";
import { SigninInfos } from "../domain/signin-info";

export class SigninUseCase {
  constructor(private readonly signinRemote: SigninRemoteProtocol){}

  async run(credential: Credentials) : Promise<SigninInfos | undefined>{
    const response =  await this.signinRemote.signin(credential)
    if(!response) return 
    
    return {
      name:response.name,
      token:response.token
    }
  }
}
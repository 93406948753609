import { InputMaskEnum } from "./enum/InputMaskEnum";

const phone = [
  "(",
  /[1-9]/,
  /\d/,  
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const maskInput = (mask:InputMaskEnum)=>{
  if (mask === InputMaskEnum.PHONE ) return phone
}
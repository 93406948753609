import { Children } from 'react'
import Background from '../background'
import {
    Wrap,
    Header,
    Container,
    Title,
    BoxRight,
    WrapUser,
    UserIcon,
} from './style'
import SideMenu from '../side-menu'

interface Props {
    children: any
    showMenu?: boolean
    color?: string
    title?: string
    username?: string
}

const LayoutDash: React.FC<Props> = ({
    children,
    color,
    showMenu,
    title,
    username,
}) => {
    return (
        <Wrap>
            <Background color={color} />
            <BoxRight>
                {username && (
                    <WrapUser>
                        <UserIcon />
                        Rafael augusto
                    </WrapUser>
                )}
            </BoxRight>

            <Header>
                <Container>{title && <Title>{title}</Title>}</Container>
            </Header>

            {showMenu && <SideMenu />}

            <Container top="120px">
                {Children.map(children, child => child)}
            </Container>
        </Wrap>
    )
}

export default LayoutDash

import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;    
`

export const ContainerStyle = styled.div`
    width: 1080px;
    height: auto;    
`

export const Sectors = [
    {
        code: 'e9f42852-76bd-4290-ae42-e910e33738ea',
        name: 'Acessórios de Tecnologia',
    },
    {
        code: '467ba8b5-27c7-4f61-9253-4d4d40549051',
        name: 'Ar e Ventilação',
    },
    {
        code: 'aba8029f-5b3d-4d6c-b42d-08084f337ed9',
        name: 'Artesanato',
    },
    {
        code: '0ab70027-4bcc-468d-96d0-10388d53c9c4',
        name: 'Artigos para Festa',
    },
    {
        code: '5bcd64a9-ae24-4e77-930f-7a77dea37534',
        name: 'Áudio',
    },
    {
        code: '8ff6916a-b290-4397-a8e4-8eae27bf6ee2',
        name: 'Automotivo',
    },
    {
        code: 'e146e2f5-2823-4944-91d5-6d14f53764ba',
        name: 'Auto Peças',
    },
    {
        code: 'd2f7592d-5fb2-437b-91a5-1a6cb2cb6662',
        name: 'Bebês',
    },
    {
        code: '01deefec-7877-46c2-b35e-acc408219437',
        name: 'Beleza & Perfumaria',
    },
    {
        code: '49f03ec6-ff44-45c4-af22-97e0c3de348e',
        name: 'Bem-estar Sexual',
    },
    {
        code: 'f7cca985-5dd5-4cfd-94b1-08750002daf2',
        name: 'Brinquedos',
    },
    {
        code: 'f993eca0-7203-4f83-aeea-af9c6a2453a5',
        name: 'Bolos e Confeitaria',
    },
    {
        code: '2128c682-1404-4c10-b269-5ddfea66e693',
        name: 'Cama, Mesa e Banho',
    },
    {
        code: 'd9439340-4122-4674-914c-778e72695e36',
        name: 'Câmeras e Drones',
    },
    {
        code: 'a1fd2c78-6d0b-4678-9d58-f1c54977055d',
        name: 'Casa e Construção',
    },
    {
        code: '4c8d3dc9-623f-41bb-baea-c424e818dc82',
        name: 'Casa Inteligente',
    },
    {
        code: '09f69c14-9011-4816-9cc4-c8330f945f0e',
        name: 'Celular e Smartphone',
    },
    {
        code: '984a9ab1-65f1-49c2-acf5-b97b2ff54874',
        name: 'Colchões',
    },
    {
        code: 'f5e8deb0-2f73-47d4-aa6a-2a117cf96b1a',
        name: 'Comércio e Indústria',
    },
    {
        code: 'f3bc9051-f8f8-4796-a023-cb9b6bdd80da',
        name: 'Cursos',
    },
    {
        code: 'dc06e3c0-f468-475e-ae12-270f86f3ab71',
        name: 'Decoração',
    },
    {
        code: '10a2e39b-7e5e-423d-8f97-b53947cb36f6',
        name: 'Eletrodomésticos',
    },
    {
        code: '901c2c61-d522-467d-81ad-954555d2dfaa',
        name: 'Eletroportáteis',
    },
    {
        code: '4c4a51c8-0538-422f-b3fc-87ea5821660a',
        name: 'Esporte e Lazer',
    },
    {
        code: 'bb41a963-3649-4ad9-aad7-919cd36e9806',
        name: 'Ferramentas',
    },
    {
        code: 'db9fa86a-69f2-4295-ad8c-e2dab2a1f3ba',
        name: 'Filmes e Séries',
    },
    {
        code: '64547111-9f83-4ee3-961f-7f27170e2d95',
        name: 'Games',
    },
    {
        code: '17a44d53-df45-41f1-9a49-80a7ce2043bb',
        name: 'Informática',
    },
    {
        code: '1eda175a-67b2-4ceb-8d62-4b0e2ad7b987',
        name: 'Instrumentos Musicais',
    },
    {
        code: 'dc5fbfb3-bc21-4793-9eaa-f3526f1f2790',
        name: 'Livros',
    },
    {
        code: '23c1a7df-d0ef-4557-ba25-5605b1cbe243',
        name: 'Mercado',
    },
    {
        code: '4201d280-9a2d-4da3-b924-ce0150b48032',
        name: 'Moda',
    },
    {
        code: '63b9718a-8ac4-41b6-bdb3-e1048e4ad609',
        name: 'Móveis',
    },
    {
        code: '954b6365-7afd-42ea-9eff-36ce4019937f',
        name: 'Música e Shows',
    },
    {
        code: '167f121b-32df-4e40-ac81-ab4cce42a781',
        name: 'Papelaria',
    },
    {
        code: 'd7b16cc3-a7dd-4eb0-a8a9-72e5bdef2171',
        name: 'Pet Shop',
    },
    {
        code: 'ca15c4bc-3fee-4bcb-bd87-9776d652d5cb',
        name: 'Religião e Espiritualidade',
    },
    {
        code: '471cc579-4c19-4c3a-8dc3-7099a3b528d9',
        name: 'Relógios',
    },
    {
        code: '81c0d0ef-b08a-4ff6-a48a-49691e845a35',
        name: 'Roupas e acessórios',
    },
    {
        code: '42318a69-1e21-4e20-9852-604d3d60e1d8',
        name: 'Saúde e Cuidados Pessoais',
    },
    {
        code: '9ffee6ae-54a3-475d-917b-ea5f0b597fad',
        name: 'Suplementos Alimentares',
    },
    {
        code: '6fe2eb73-b130-4faa-beab-18237494268f',
        name: 'Outros...',
    },
]

import { Backend } from '../../../../../common/infra/backend'
import { HttpConnector } from '../../../../../common/infra/http-connector/http-connector'
import { User } from '../../../../../domain/entity/user'
import { SignupRemoteProtocol } from './signup-remoto.protocol'

export class SignupRemote
    extends HttpConnector
    implements SignupRemoteProtocol
{
    private static ENDPOINT = Backend.makeUrl(`signup`)

    async create(data: User): Promise<boolean> {
        try {
            const url = SignupRemote.ENDPOINT
            const response = await this.post({ url, data })
            return response.status === 200 && response.data
        } catch (error) {
            return false
        }
    }
}

import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
    title: string
}

const WindowTitle: React.FC<Props> = ({ title }) => (
    <Helmet>
        <title>Aítem - {title}</title>
    </Helmet>
)

export default WindowTitle

import React from 'react'

import { ErrorInput } from '../input-error'
import {
    ArrowDown,
    InputLabel,
    InputStyled,
    RequiredLabel,
    WrapLabel,
    WrapSelect,
} from './style'

interface Props {
    label?: string
    name: string
    change: (e: React.ChangeEvent<HTMLSelectElement>) => void
    children: React.ReactNode
    required?: boolean
    showErrorMsg?: boolean
}

const InputSelect: React.FC<Props> = ({
    label,
    name,
    change,
    children,
    required,
    showErrorMsg,
}) => {
    return (
        <>
            <WrapLabel>
                <InputLabel>{label}</InputLabel>
                <RequiredLabel>*</RequiredLabel>
            </WrapLabel>

            <WrapSelect>
                <ArrowDown />
                <InputStyled
                    data-testid={name}
                    name={name}
                    required={required}
                    onChange={change}
                >
                    {children}
                </InputStyled>
            </WrapSelect>
            {showErrorMsg && <ErrorInput name={name} />}
        </>
    )
}

export default InputSelect

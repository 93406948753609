import { SigninAlertsMessages } from '../alerts-messages/signin-alerts.messages'
import { SigninStateProtocol } from '../protocols/signin-user-state.protocol'
import { SigninUseCase } from '../usecases/signin.usecase'

export class SigninController {
    constructor(private readonly signinUseCase: SigninUseCase) {}

    async submit(state: SigninStateProtocol) {
        state.setLoad(true)
        const logged = await this.signinUseCase.run(state.data)
        state.setLoad(false)

        if (logged) {
            window.localStorage.setItem('token', logged.token)
            window.localStorage.setItem('name', logged.name)
            window.location.href = '/dash'
        } else {
            state.showAlert && state.showAlert(SigninAlertsMessages.failed)
        }
    }
}

import React, { useState } from 'react'
import { InputLabel, InputStyled, RequiredLabel, WrapLabel } from './style'
import { InputMaskStyled } from '../input-mask/style'
import { maskInput } from '../input-mask/input-mask-regex'
import { InputMaskEnum } from '../input-mask/enum/InputMaskEnum'
import { Mask } from 'react-text-mask'

interface Props {
    label?: string
    name: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    type?: string
    value?: string
    required?: boolean
    mask?: InputMaskEnum
}

const InputNative: React.FC<Props> = ({
    label,
    name,
    onChange,
    type,
    required,
    value,
    mask,
}) => {
    const [expandLabel, setExpandLabel] = useState<boolean>(false)

    return (
        <>
            {expandLabel && (
                <WrapLabel>
                    <InputLabel>{label}</InputLabel>
                    <RequiredLabel>*</RequiredLabel>
                </WrapLabel>
            )}
            {mask ? (
                <InputMaskStyled
                    mask={maskInput(mask) as Mask}
                    onFocus={() => setExpandLabel(true)}
                    onBlur={() => setExpandLabel(false)}
                    placeholder={!expandLabel ? label : ''}
                    data-testid={name}
                    name={name}
                    {...(onChange && { onChange })}
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                    type={type ?? 'text'}
                    required={required}
                    value={value}
                />
            ) : (
                <InputStyled
                    onFocus={() => setExpandLabel(true)}
                    onBlur={() => setExpandLabel(false)}
                    placeholder={!expandLabel ? label : ''}
                    data-testid={name}
                    value={value}
                    name={name}
                    {...(onChange && { onChange })}
                    style={{ marginBottom: '20px' }}
                    type={type ?? 'text'}
                    required={required}
                />
            )}
        </>
    )
}

export default InputNative

import styled from 'styled-components'
import { Menu as MenuIcon, X } from 'react-feather'

export const WrapMenuMob = styled.div`
    margin-right: 10px;
    z-index: 0;
    @media (min-width: 768px) {
        display: none;
    }
`

export const MenuButton = styled(MenuIcon)`
    cursor: pointer;
`

export const MenuContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    inset: 0px 0px 0px 0px;
    overflow: hidden;
`

export const ContainerCloseMenu = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    justify-content: end;
`

export const CloseMenuButton = styled(X)`
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
`

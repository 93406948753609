import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import theme from '../../../util/theme';

export const WrapLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction: row;
margin-bottom: 2px;
`;

export const InputLabel = styled.div`
font-size: 13px;
font-family: 'Inter', sans-serif;
color:#000;
;
`

export const RequiredLabel = styled.div`
font-size:11px;
margin-top:3px;
margin-left:2px;
color:${theme.colors.primary};
font-weight: bold;
`

export const WrapSelect = styled.div`
width: 100%;
height: 30px;
margin-bottom: 15px;
position:relative;
display: flex;
justify-content: flex-end;
`

export const ArrowDown = styled(ChevronDown)`
position:absolute;
top:2px;
color: ${theme.colors.primary};
cursor: pointer;
`;

export const InputStyled = styled.select`
  width: 100%;
  height: 100%;
  border-radius:0;
  background: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  -webkit-appearance:none;
  -moz-appearance: none; 
	appearance: none;
  border-bottom: 2px solid ${theme.colors.primary};
  color: #000;
  cursor: pointer;
  ::placeholder {
    color: #000;
    font-size:12px;
    font-family: 'Inter', sans-serif;  
  }  
  
  :focus {
    outline: none;
    border-bottom: 2px solid ${theme.colors.third};
  }
  padding:0;
`
import styled from "styled-components";

export const WarpIntron = styled.div`
width: 100%;
height:100vh;
display: flex;
justify-content: center;
align-items: center;
`
export const Logo = styled.img`
width: 180px;
`
import { useEffect } from 'react'
import { LayoutDash } from '../../../../common/ui'
import Item from '../item'
import { ListView } from './style'
import { RealTimeNotification } from '../../../../common/infra/realtime-notification'

const Orders = () => {
    useEffect(() => {
        const realtime = new RealTimeNotification({
            channel: 'orders',
        }).subscribe('new_order', msg => {
            console.log(msg)
        })

        return () => {
            realtime.unsubscribe('new_order', () => {})
        }
    }, [])

    const submitOrder = (data: any) => {
        console.log(data)
    }

    return (
        <LayoutDash
            title="Produtos/serviços procurados na sua categoria"
            username="Rafael Augusto"
            showMenu
        >
            <ListView>
                <Item
                    onClick={submitOrder}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sem sem, hendrerit vitae varius vel, dapibus et felis. Nam quis risus sed nulla lobortis luctus sit amet a lacus. Nam pellentesque placerat urna,"
                    views="12"
                />
            </ListView>
        </LayoutDash>
    )
}

export default Orders
